const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://www.maxgmartin.com/',
  title: 'MM',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Max Martin',
  role: 'Software Engineer',
  description:
    'I’m a Software Engineer with over two years of experience in developing innovative solutions to simplify everyday processes and reduce stress through the effective use of technology.',
  resume: 'https://drive.google.com/file/d/1YCFeGsKZD_XwbSieefHGboNHdjLPH9Mk/view?usp=sharing',
  social: {
    linkedin: 'https://linkedin.com/in/max-martin1',
    github: 'https://github.com/maxmartinn',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'FinFindr',
    description:
      'AI powered app that helps users maximize rewards by selecting the best credit card for each vendor type',
    stack: ['.Net', 'C#', 'React Native', 'OpenAI'],
    sourceCode: 'https://github.com/SarasotaDevs/FinFindr',
    livePreview: 'https://github.com/SarasotaDevs/FinFindr',
  },
  {
    name: 'PrepPal',
    description:
      'AI powered natural disaster preparedness app',
    stack: ['FastAPI', 'OpenAI', 'IBM Cloud', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'ChatrBox',
    description:
      'Chatroom enabling real-time conversations',
    stack: ['MongoDB', 'Express', 'React', 'Node'],
    sourceCode: 'https://github.com/maxmartinn/ChatrBox',
    livePreview: 'https://github.com/maxmartinn/ChatrBox',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Java',
  'C#',
  'TypeScript',
  'Spring',
  '.Net',
  'AWS',
  'Linux',
  'React',
  'Angular',
  'PostgreSQL',
  'MySQL',
  'MongoDB',
  'Docker',
  'Git'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'martin.max@ufl.edu',
}

export { header, about, projects, skills, contact }
